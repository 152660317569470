import { TraderCategory } from '../model/trader-categories'
import { CotData } from "../model/cot-data";
import { TableValues } from "../model/table-value";

/**
 * For a given trader category, return a function that prepares
 * an array of data to be used in table/view rendering
 */
export const prepareTableData = (selectedTraderCategory: TraderCategory, values: CotData): TableValues => {
    const isCommercial = selectedTraderCategory === 'Commercial'

    if (isCommercial && values.contract_market_name === 'GOLD') {
    }

    const longs = isCommercial ? values.comm_positions_long_all : values.noncomm_positions_long_all
    const shorts = isCommercial ? values.comm_positions_short_all : values.noncomm_positions_short_all
    const spreads = isCommercial ? 0 : values.noncomm_postions_spread_all
    const totalOpenInterest = values.open_interest_all
    const netPositions = longs - shorts
    const spreadingTraders = isCommercial
      ? 0
      : values.traders_noncomm_spread_all
    const longTraders = isCommercial
        ? values.traders_comm_long_all
        : values.traders_noncomm_long_all
    const shortTraders = isCommercial
        ? values.traders_comm_short_all
        : values.traders_noncomm_short_all
    const allTraders = values.traders_tot_all
    const longSentiment = longTraders / allTraders * 100
    const shortSentiment = shortTraders / allTraders * 100
    const spreadSentiment = spreadingTraders / allTraders * 100
    const avgLongPosition = longs / longTraders
    const avgShortPosition = shorts / shortTraders
    const netSentiment = longTraders - shortTraders

    const longConviction = Number(Number(longs / totalOpenInterest).toFixed(2))
    const shortConviction = Number(Number(shorts / totalOpenInterest).toFixed(2))
    const spreadConviction = Number(Number(spreads / totalOpenInterest).toFixed(2))
    const netConviction = Number(((longs - shorts) / totalOpenInterest).toFixed(2))

    let change_longs = isCommercial
        ? values.change_in_comm_long_all
        : values.change_in_noncomm_long_all
    let change_shorts = isCommercial
        ? values.change_in_comm_short_all
        : values.change_in_noncomm_short_all
    let value_oi_perc_longs = isCommercial
        ? values.pct_of_oi_comm_long_all
        : values.pct_of_oi_noncomm_long_all
    let value_oi_perc_shorts = isCommercial
        ? values.pct_of_oi_comm_short_all
        : values.pct_of_oi_noncomm_short_all

    return [
      /* 0 */ values.report_date_as_yyyy_mm_dd.slice(0, 10),
      /* 1 */ longs,
      /* 2 */ shorts,
      /* 3 */ change_longs,
      /* 4 */ change_shorts,
      /* 5 */ value_oi_perc_longs,
      /* 6 */ value_oi_perc_shorts,
      /* 7 */ netPositions,
      /* 8 */ longSentiment,
      /* 9 */ shortSentiment,
      /* 10 */ spreadSentiment,
      /* 11 */ avgLongPosition,
      /* 12 */ avgShortPosition,
      /* 13 */ values.conc_net_le_4_tdr_long_all,
      /* 14 */ values.conc_net_le_4_tdr_short_all,
      /* 15 */ values.conc_gross_le_4_tdr_long,
      /* 16 */ values.conc_gross_le_4_tdr_short,
      /* 17 */ longTraders,
      /* 18 */ shortTraders,
      /* 19 */ spreadingTraders,
      /* 20 */ netSentiment,
      /* 21 */ longConviction,
      /* 22 */ shortConviction,
      /* 23 */ spreadConviction,
      /* 24 */ netConviction,
    ]
}


export const exchangesWithMarkets = [
    {
        "exchange": "CHICAGO BOARD OF TRADE",
        "markets": [
            "WHEAT-SRW",
            "WHEAT-HRW",
            "CORN",
            "CORN CONSECUTIVE CSO",
            "CORN CSO",
            "OATS",
            "SOYBEANS",
            "MINI SOYBEANS",
            "SOYBEAN CSO",
            "SOYBEAN OIL",
            "UST BOND",
            "ULTRA UST BOND",
            "SOYBEAN MEAL",
            "ROUGH RICE",
            "UST 2Y NOTE",
            "UST 10Y NOTE",
            "ULTRA UST 10Y",
            "MICRO 10 YEAR YIELD",
            "UST 5Y NOTE",
            "FED FUNDS",
            "DJIA Consolidated",
            "DJIA x $5",
            "DOW JONES U.S. REAL ESTATE IDX",
            "MICRO E-MINI DJIA (x$0.5)",
            "BBG COMMODITY",
            "2 YEAR ERIS SOFR SWAP",
            "10 YEAR ERIS SOFR SWAP",
            "5 YEAR ERIS SOFR SWAP"
        ]
    },
    {
        "exchange": "MINNEAPOLIS GRAIN EXCHANGE",
        "markets": [
            "WHEAT-HRSpring"
        ]
    },
    {
        "exchange": "ICE FUTURES ENERGY DIV",
        "markets": [
            "NJ SRECS",
            "PJM TRI-RECs CLASS 1",
            "CALIF LOW CARBON FSC-OPIS",
            "D6 RINs OPIS CURRENT YEAR",
            "PA SOLAR ALTER ENERGY CREDIT",
            "CALIF CARBON CURRENT AUCTION",
            "D4 BIODIESEL RINs OPIS CURR YR",
            "CT RECs CLASS 1",
            "MASS COMPLIANCE RECs CLASS 1",
            "MARYLAND COMPLIANCE REC TIER1",
            "MARYLAND SOLAR REC",
            "NEPOOL DUAL RECs CLASS 1",
            "PA COMPLIANCE AECs TIER1",
            "CALIF CARBON 23",
            "RGGI V2022-ICE",
            "CALIF CARBON ALLOWANCE V2024",
            "RGGI V2023",
            "WASHINGTON CARBON V2023",
            "RGGI V2024",
            "MARINE FUEL OIL 0.5% FOB USGC",
            "MARINE .5% USGC/.5% RDAM BG",
            "USGC HSFO (PLATTS)",
            "FUEL OIL-3% USGC/3.5% FOB RDAM",
            "USGC HSFO-PLATTS/BRENT 1ST LN",
            "NAT GAS ICE LD1",
            "NAT GAS ICE PEN",
            "SOCAL BORDER FIN BASIS",
            "PG&E CITYGATE FIN BASIS",
            "NWP ROCKIES FIN BASIS",
            "AECO FIN BASIS",
            "CHICAGO FIN BASIS",
            "HSC FIN BASIS",
            "WAHA FIN BASIS",
            "ALGONQUIN CITYGATES BASIS",
            "CG-MAINLINE FINANCIAL INDEX",
            "CHICAGO CITYGATE (INDEX)",
            "CIG ROCKIES FINANCIAL INDEX",
            "CIG ROCKIES BASIS",
            "TCO INDEX",
            "TCO Basis",
            "CG Mainline Basis",
            "EP SAN JUAN BASIS",
            "HENRY HUB BASIS",
            "HENRY HUB INDEX",
            "HOUSTON SHIP CHANNEL (INDEX)",
            "MICHCON FINANCIAL INDEX",
            "MICHCON BASIS",
            "NAT GAS LD1 for GDD -TEXOK",
            "ALGONQUIN CITYGATES INDEX",
            "NATURAL GAS INDEX: EP SAN JUAN",
            "NGPL MIDCONT BASIS",
            "NGPL TXOK BASIS",
            "NNG DEMARC BASIS",
            "NNG VENTURA BASIS",
            "NWP SUMAS BASIS",
            "NWP Rockies Index",
            "ONEOK GAS TRANSPORTATION BASIS",
            "MALIN (BASIS)",
            "PANHANDLE BASIS",
            "PANHANDLE INDEX",
            "SOCAL (INDEX)",
            "SOCAL CITYGATE FINANCIAL BASIS",
            "TETCO M3 INDEX",
            "TETCO M3 BASIS",
            "TGT ZONE 1 BASIS",
            "TRANSCO STN 85 MONTHLY INDEX",
            "TRANSCO ZONE 6 BASIS",
            "TRANSCO ZONE 6 MONTHLY INDEX",
            "TRANSCO STATION 85-ZONE 4 BASI",
            "WAHA INDEX",
            "TETCO M2 Basis (Receipts)",
            "TRANSCO LEIDY BASIS",
            "TETCO M2 Index (Receipts)",
            "REX ZONE 3 BASIS",
            "REX ZONE 3 INDEX",
            "Transco Zone 5 South Basis",
            "TRANSCO ZONE 5 SOUTH INDEX",
            "MID-C DAY-AHEAD PEAK",
            "MID-C DAY-AHEAD OFF-PEAK",
            "PJM WESTERN HUB RT OFF",
            "SP15 FIN DA PEAK FIXED",
            "CAISO SP-15 DA OFF-PK FIXED",
            "PJM AEP DAYTON RT OFF-PK FIXED",
            "PJM AEP DAYTON RT PEAK FIXED",
            "PJM AEP DAYTON HUB DA OFF-PK",
            "AEP DAYTON HUB DA PEAK DAILY",
            "PJM AEP DAYTON DA PEAK",
            "MISO INDIANA  OFF-PEAK",
            "MISO IN. REAL-TIME OFF-PEAK",
            "ERCOT HOUSTON 345KV RT PK FIX",
            "ERCOT Houston 345KV RT OFF FIX",
            "ERCOT NORTH 345KV RT PK FIX",
            "ERCOT SOUTH 345 KV RT PEAK FIX",
            "ERCOT-SOUTH MONTHLY OFFPEAK",
            "ERCOT WEST 345KV RT PEAK",
            "ERCOT WEST 345K RT OFF-PEAK",
            "ERCOT NORTH 345KV DA PK DLY FI",
            "MISO IN. DAY-AHEAD PEAK",
            "MISO INDIANA HUB RT PEAK",
            "PJM N. IL HUB DA OFF-PK",
            "PJM N. IL HUB DA PEAK",
            "ISO NE MASS HUB DA OFF-PK FIXD",
            "ISO NE MASS HUB DA PEAK",
            "CAISO NP-15 PEAK",
            "CAISO NP-15 DA OFF-PK FIXED",
            "PJM NI HUB RT OFF-PK FIXED",
            "PJM N. IL HUB RT PEAK",
            "NYISO ZONE J DA PEAK",
            "NYISO ZONE J DA OFF-PK FIXED",
            "NYISO ZONE A DA PEAK",
            "NYISO ZONE A DA OFF-PK FIX PR",
            "NYISO ZONE G DA PEAK",
            "NYISO ZONE G DA OFF-PK",
            "PALO VERDE DA PEAK",
            "PALO VERDE DA OFF-PK FiXED PR",
            "PJM BGE ZONE DA OFFPEAK FIXED",
            "PJM BGE ZONE DA, PEAK FIXED",
            "PJM PECO ZONE DA PEAK",
            "PJM PECO DA OFFPK FIXED",
            "PJM PPL ZONE DA OFF-PEAK FIXED",
            "PJM PPL ZONE DA PEAK",
            "PJM WESTERN HUB DA OFF-PK",
            "PJM WESTERN HUB DA PEAK",
            "PJM WESTERN HUB RT PEAK MINI",
            "PJM PSEG DAY-AHEAD PEAK",
            "PJM PSEG DA OFF-PK FIXED PRICE",
            "PJM WEST HUB REAL TIME PK MINI",
            "ERCOT N 345KV REAL T PK DALY M",
            "ERCOT N 345KV REAL T PK 2x16",
            "ERCOT North 345KV Hub RT 7x8",
            "ERCOT Houston 345KV Hub RT 7x8",
            "PJM DOM ZONE DAY AHEAD PEAK",
            "PJM DOM ZONE DAY AHEAD OFFPEAK",
            "ERCOT North 345KV Hub RT 7x16",
            "PROPANE ARGUS CIF ARA MINI",
            "PROPANE ARGUS FAR EAST MINI",
            "PROPANE,OPIS MT BELV TET BALMO",
            "ARGUS CIF ARA Lg Finl Propane",
            "ARGUS FAR EAST PROPANE",
            "ETHANE, MT. BELV-ENTERPRISE",
            "NAT GASLNE OPIS MT B NONTET FP",
            "BUTANE OPIS MT BELV NONTET FP",
            "PROPANE OPIS CONWAY INWELL FP",
            "PROPANE OPIS MT BELV NONTET FP",
            "PROPANE OPIS MT BELVIEU TET FP",
            "PROPANE ARGUS SAUDI CP FP",
            "CRUDE DIFF-WCS HOUSTON/WTI 1ST",
            "CRUDE DIFF-TMX WCS 1A INDEX",
            "CRUDE DIFF-TMX SW 1A INDEX",
            "CONDENSATE DIF-TMX C5 1A INDEX",
            "GASOLINE CRK-RBOB/BRENT 1st",
            "MARINE .5% FOB USGC/BRENT 1st"
        ]
    },
    {
        "exchange": "NEW YORK MERCANTILE EXCHANGE",
        "markets": [
            "CBL NATURE GLOBAL EMISSIONS",
            "CBL GLOBAL EMISSIONS OFFSET",
            "GULF # 6 FUEL 3.0% SULFUR",
            "NY HARBOR ULSD",
            "UP DOWN GC ULSD VS HO SPR",
            "NAT GAS NYME",
            "EUR STYLE NATURAL GAS OPTIONS",
            "HENRY HUB LAST DAY FIN",
            "HENRY HUB PENULTIMATE FIN",
            "NATURAL GAS CAL SPREAD OPT FIN",
            "ETHANOL T2 FOB INCL DUTY",
            "ETHANOL",
            "Mini Eur Naphtha CIF NEW",
            "HENRY HUB",
            "HENRY HUB PENULTIMATE NAT GAS",
            "EUROPEAN PROPANE CIF ARA",
            "ARGUS PROPANE SAUDI ARAMCO",
            "ARGUS PROPANE FAR EAST INDEX",
            "PROPANE NON-LDH MT BEL",
            "PROPANE",
            "MT BELVIEU ETHANE OPIS",
            "MT BELV NORM BUTANE OPIS",
            "MT BELV NAT GASOLINE OPIS",
            "CONWAY PROPANE (OPIS)",
            "MT BELVIEU LDH PROPANE BALMO",
            "MiniARGUS PROPANE FAR EAST INX",
            "WTI-PHYSICAL",
            "CRUDE OIL CAL SPREAD OPTIONS",
            "WTI FINANCIAL CRUDE OIL",
            "CRUDE OIL AVG PRICE OPTIONS",
            "BRENT LAST DAY",
            "WTI  HOUSTON ARGUS/WTI TR MO",
            "CRUDE OIL CAL SPREAD OPT FIN",
            "WTI MIDLAND ARGUS VS WTI TRADE",
            "PALLADIUM",
            "PLATINUM",
            "GASOLINE RBOB",
            "RBOB CALENDAR",
            "GULF COAST CBOB GAS A2 PL RBOB",
            "GULF JET NY HEAT OIL SPR",
            "GULF # 6 FUEL OIL CRACK",
            "NAPHTHA CRACK SPR"
        ]
    },
    {
        "exchange": "NODAL EXCHANGE",
        "markets": [
            "TX GREEN-E REC V23 FRONT HALF",
            "TX GREEN-E REC V24 FRONT HALF",
            "TX GREEN-E REC V25 FRONT HALF",
            "TX GREEN-E REC V22 BACK HALF",
            "TX GREEN-E REC V23 BACK HALF",
            "TX GREEN-E REC V24 BACK HALF",
            "TX GREEN-E REC V25 BACK HALF",
            "PENNSYLVANIA AEC TIER 2-V2024",
            "TX REC CRS V26 BACK HALF",
            "TX REC CRS V27 BACK HALF",
            "TX REC CRS V26 FRONT HALF",
            "TX REC CRS V27 FRONT HALF",
            "ISONE.H.INTERNAL_HUB_mo_on_dap",
            "ISONE.H.INTERNAL_HB_mo_off_dap",
            "MISO.INDIANA.HUB_month_on_dap",
            "MISO.INDIANA.HUB_month_off_dap",
            "NYISO.CENTRL_month_on_dap",
            "NYISO.CENTRL_month_off_dap",
            "NYISO.HUD VL_month_on_dap",
            "NYISO.HUD VL_month_off_dap",
            "NYISO.N.Y.C._month_on_dap",
            "NYISO.N.Y.C._month_off_dap",
            "NYISO.WEST_month_on_dap",
            "NYISO.WEST_month_off_dap",
            "PJM.AEP-DAYTON HUB_mo_on_dap",
            "PJM.AEP-DAYTON HUB_mo_off_dap",
            "PJM.APS_month_off_dap",
            "PJM.BGE_month_on_dap",
            "PJM.BGE_month_off_dap",
            "PJM.COMED_month_on_dap",
            "PJM.COMED_month_off_dap",
            "PJM.DOM_month_on_dap",
            "PJM.DOM_month_off_dap",
            "PJM.DPL_month_on_dap",
            "PJM.JCPL_month_on_dap",
            "PJM.JCPL_month_off_dap",
            "PJM.METED_month_on_dap",
            "PJM.METED_month_off_dap",
            "PJM.N ILLINOIS HUB_mo_on_dap",
            "PJM.N ILLINOIS HUB_mo_off_dap",
            "PJM.PECO_month_on_dap",
            "PJM.PECO_month_off_dap",
            "PJM.PENELEC_month_on_dap",
            "PJM.PENELEC_month_off_dap",
            "PJM.PEPCO_month_on_dap",
            "PJM.PEPCO_month_off_dap",
            "PJM.PPL_month_on_dap",
            "PJM.PPL_month_off_dap",
            "PJM.PSEG_month_on_dap",
            "PJM.PSEG_month_off_dap",
            "PJM.WESTERN HUB_month_on_dap",
            "PJM.WESTERN HUB_month_off_dap",
            "MISO.INDIANA.HUB_month_on_rtp",
            "MISO.INDIANA.HUB_month_off_rtp",
            "PJM.AEP-DAYTON HUB_mo_on_rtp",
            "PJM.AEP-DAYTON HUB_mo_off_rtp",
            "PJM.N ILLINOIS HUB_mo_on_rtp",
            "PJM.N ILLINOIS HUB_mo_off_rtp",
            "PJM.WESTERN HUB_month_on_rtp",
            "PJM.WESTERN HUB_month_off_rtp",
            "ERCOT.HB_NORTH_month_on_rtp",
            "ERCOT.HB_NORTH_month_off_rtp",
            "PJM.FE OHIO_month_on_dap"
        ]
    },
    {
        "exchange": "SOUTH POINT",
        "markets": [
            "DOMINION"
        ]
    },
    {
        "exchange": "SOUTH POINT (BASIS)",
        "markets": [
            "DOMINION"
        ]
    },
    {
        "exchange": "CITYGATE (INDEX)",
        "markets": [
            "PG&E"
        ]
    },
    {
        "exchange": "TIER 1 POOL (ZONE 0)",
        "markets": [
            "SONAT"
        ]
    },
    {
        "exchange": "ICE FUTURES U.S.",
        "markets": [
            "COTTON NO. 2",
            "FRZN CONCENTRATED ORANGE JUICE",
            "COCOA",
            "SUGAR NO. 11",
            "COFFEE C",
            "COFFEE CALENDAR SPREAD OPTIONS",
            "USD INDEX",
            "CANOLA",
            "MSCI EAFE",
            "MSCI EM INDEX"
        ]
    },
    {
        "exchange": "CHICAGO MERCANTILE EXCHANGE",
        "markets": [
            "USD Malaysian Crude Palm Oil C",
            "EURO SHORT TERM RATE",
            "BUTTER (CASH SETTLED)",
            "MILK, Class III",
            "NON FAT DRY MILK",
            "CME MILK IV",
            "DRY WHEY",
            "LEAN HOGS",
            "LIVE CATTLE",
            "RANDOM LENGTH LUMBER",
            "LUMBER",
            "FEEDER CATTLE",
            "CHEESE (CASH-SETTLED)",
            "USD/CHINESE RENMINBI-OFFSHORE",
            "CANADIAN DOLLAR",
            "SWISS FRANC",
            "MEXICAN PESO",
            "BRITISH POUND",
            "JAPANESE YEN",
            "EURO FX",
            "BRAZILIAN REAL",
            "NZ DOLLAR",
            "SO AFRICAN RAND",
            "EURODOLLARS-3M",
            "BITCOIN",
            "MICRO BITCOIN",
            "SOFR-3M",
            "SOFR-1M",
            "S&P 500 Consolidated",
            "E-MINI S&P CONSU STAPLES INDEX",
            "E-MINI S&P ENERGY INDEX",
            "E-MINI S&P 500",
            "E-MINI S&P FINANCIAL INDEX",
            "E-MINI S&P HEALTH CARE INDEX",
            "E-MINI S&P INDUSTRIAL INDEX",
            "E-MINI S&P MATERIALS INDEX",
            "E-MINI S&P TECHNOLOGY INDEX",
            "E-MINI S&P UTILITIES INDEX",
            "MICRO E-MINI S&P 500 INDEX",
            "ADJUSTED INT RATE S&P 500 TOTL",
            "ETHER CASH SETTLED",
            "MICRO ETHER",
            "NASDAQ-100 Consolidated",
            "NASDAQ MINI",
            "MICRO E-MINI NASDAQ-100 INDEX",
            "AUSTRALIAN DOLLAR",
            "RUSSELL E-MINI",
            "EMINI RUSSELL 1000 VALUE INDEX",
            "MICRO E-MINI RUSSELL 2000 INDX",
            "NIKKEI STOCK AVERAGE",
            "NIKKEI STOCK AVERAGE YEN DENOM",
            "EURO FX/BRITISH POUND XRATE",
            "E-MINI S&P 400 STOCK INDEX",
            "EURO FX/JAPANESE YEN XRATE",
            "S&P 500 ANNUAL DIVIDEND INDEX",
            "S&P 500 QUARTERLY DIVIDEND IND"
        ]
    },
    {
        "exchange": "NORTH MONTHLY OFF-PEAK",
        "markets": [
            "ERCOT"
        ]
    },
    {
        "exchange": "ICE FUTURES EUROPE",
        "markets": [
            "CRUDE OIL, LIGHT SWEET-WTI"
        ]
    },
    {
        "exchange": "COMMODITY EXCHANGE INC.",
        "markets": [
            "SILVER",
            "COPPER- #1",
            "GOLD",
            "MICRO GOLD",
            "COBALT",
            "LITHIUM HYDROXIDE",
            "ALUMINUM",
            "ALUMINUM MWP",
            "ALUM EUR UNPAID",
            "ALUMINIUM EURO PREM DUTY-PAID",
            "STEEL-HRC",
            "STEEL SCRAP"
        ]
    },
    {
        "exchange": "CBOE FUTURES EXCHANGE",
        "markets": [
            "VIX FUTURES"
        ]
    },
    {
        "exchange": "LMX LABS LLC",
        "markets": [
            "Nano Bitcoin",
            "NANO ETHER"
        ]
    }
]

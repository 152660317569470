import React from "react";
import { exchangesWithMarkets } from "../model/exchanges-with-markets";

export class MarketSelector extends React.Component<{ selectedExchange: any, onSelect: any, defaultMarket: any }> {
    render() {
        let {selectedExchange, onSelect, defaultMarket} = this.props;
        const selectedExchangeData =
            exchangesWithMarkets.find((exchange) => exchange.exchange === selectedExchange);

        return (
            <select className="custom-select form-control js-page-dropdown-select"
                    onChange={(e) => onSelect(e.target.value)} value={defaultMarket}>
                {selectedExchangeData!.markets &&
                    selectedExchangeData!.markets.map((market) => (
                        <option key={market} value={market}>
                            {market}
                        </option>
                    ))}
            </select>
        );
    }
}

import React from "react";

export class ParticipantSelector extends React.Component<{ onSelect: any, defaultParticipant: any }> {
    render() {
        let {onSelect, defaultParticipant} = this.props;
        return (
            <select className="custom-select form-control js-page-dropdown-select"
                    onChange={(e) => onSelect(e.target.value)} value={defaultParticipant}>
                <option value="Commercial">Commercial</option>
                <option value="Noncommercial">Non-Commercial</option>
            </select>
        );
    }
}

import React from "react";
import { exchangesWithMarkets } from "../model/exchanges-with-markets";

export class ExchangeSelector extends React.Component<{ onSelect: any, defaultExchange: any }> {
    render() {
        let {onSelect, defaultExchange} = this.props;
        return (
            <select className="custom-select form-control js-page-dropdown-select"
                    onChange={(e) => onSelect(e.target.value)} value={defaultExchange}>
                {exchangesWithMarkets.map((exchange) => (
                    <option key={exchange.exchange} value={exchange.exchange}>
                        {exchange.exchange}
                    </option>
                ))}
            </select>
        );
    }
}

import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

// @ts-ignore
const Auth0ProviderWithHistory = ({ children }) => {
    const domain = 'dev-jq1elq8yjrai7chr.us.auth0.com';
    const clientId = 'F76R82tf19XvhzbBdre2U4LW0CO83SCU';
    const navigate = useNavigate();

    const onRedirectCallback = (appState: any) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider domain={domain}
            clientId={clientId}
            authorizationParams={{ redirect_uri: window.location.origin }}
            onRedirectCallback={onRedirectCallback}>
                {children}
        </Auth0Provider>
    )
}

export default Auth0ProviderWithHistory;

import React from "react";
import { futuresAndOptionsCombinedDatasetId, futuresOnlyDatasetId } from "../model/dataset-id";

export class DataSetSelector extends React.Component<{ onSelect: any, defaultDataSet: any }> {
    render() {
        let {onSelect, defaultDataSet} = this.props;
        return (
            <select className="custom-select form-control js-page-dropdown-select"
                    onChange={(e) => onSelect(e.target.value)} value={defaultDataSet}>
                <option value={futuresOnlyDatasetId}>Futures Only</option>
                <option value={futuresAndOptionsCombinedDatasetId}>Futures and Options Combined</option>
            </select>
        );
    }
}

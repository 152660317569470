import React, { useContext } from 'react';
import './App.css';
import DataTableContainer from "./components/DataTableContainer";
import { HeaderCtx } from "./components/Header";
import { UsefulLinkCtx } from "./components/UsefulLink";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const Header = useContext(HeaderCtx)
  const UsefulLink = useContext(UsefulLinkCtx)
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();
  const is_allowed = user && user['https://cot.odium.trade/roles'].includes('cot_charts');
  return (
      <div id='cotperspective' className='container'>
          <Header>
              <div className='col-4 pt-1' />
              <div className='col-4 text-center'>
                  <a className='blog-header-logo text-dark' href='/'>COT Charts</a>
              </div>
              <div className='col-4 d-flex justify-content-end align-items-center'>
              {
                  isAuthenticated ? (
                      <button className="btn btn-outline-warning" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Logout</button>
                      )
                      : (
                          <></>
                      )
              }
              </div>
          </Header>

          <div className="App">
              {
                  (isAuthenticated && is_allowed) ? ( <DataTableContainer /> ): (
                      <section className="">
                          <div className="px-4 py-5 px-md-5 text-left text-lg-start" style={{ backgroundColor: 'hsl(0, 0%, 96%)' }}>
                              <div className="container">
                                  <div className="row gx-lg-5 align-items-center">
                                      <div className="col-lg-6 mb-5 mb-lg-0">
                                          <h1 className="my-5 display-3 fw-bold ls-tight">
                                              The best offer <br />
                                              <span className="text-primary">for your trading</span>
                                          </h1>
                                          <p style={{ color: 'hsl(217, 10%, 50.8%)' }}>
                                              Unlock the power of the Commitments of Traders (COT) reports with our cutting-edge charting service!
                                          </p>
                                          <p style={{ color: 'hsl(217, 10%, 50.8%)' }}>
                                              Gain the Trading Edge:<br/>
                                          </p>
                                          <p style={{ color: 'hsl(217, 10%, 50.8%)' }}>
                                              Our COT report charting service is designed for traders who seek a competitive advantage. Whether you're a seasoned professional or a beginner, our intuitive platform provides valuable insights for more informed decision-making.
                                          </p>
                                      </div>

                                      <div className="col-lg-6 mb-5 mb-lg-0">
                                          <div className="card">
                                              <div className="card-body py-5 px-md-5">
                                                  <div>
                                                      <div className="form-outline mb-4">
                                                          <label className="form-label">We support login via Google</label>
                                                          <label className="form-label">Contact Us for a Personalized Quote</label>
                                                      </div>
                                                      <div className="form-outline mb-4">
                                                          {!isAuthenticated
                                                              ? (
                                                                  <div className='col-12 text-left'>
                                                                      <button className="btn btn-secondary"
                                                                              onClick={() => loginWithRedirect()}>Login
                                                                      </button>
                                                                  </div>
                                                              )
                                                              : (
                                                                  <div className='col-12 text-center'>
                                                                      <div>Your account is disabled. Contact admin to get
                                                                          access.
                                                                      </div>
                                                                  </div>
                                                              )
                                                          }
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>
                 )
              }
          </div>

          <Header>
              <div className='col-12'>
                  <h3>Useful Links</h3>
              </div>
          </Header>
          <div className='row mb-2' style={{ paddingTop: 20 }}>
              <ul>
                  <UsefulLink
                      link='https://www.cftc.gov/MarketReports/CommitmentsofTraders/index.htm'
                      channel='CFTC'
                      title='Commitments of Traders (COT) Reports Descriptions'
                  />
              </ul>
          </div>
          <div className='row mb-2' style={{ paddingTop: 20, paddingBottom: 30 }}>
              <div className='container text-justify'>
                  <small>
                      THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,
                      EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
                      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
                      IN NO EVENT SHALL THE AUTHORS BE LIABLE FOR ANY CLAIM, DAMAGES OR
                      OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE,
                      ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR
                      OTHER DEALINGS IN THE SOFTWARE.
                  </small>
              </div>
          </div>
      </div>
  );
}

export default App;

import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import { ExchangeSelector } from "./ExchangeSelector";
import { ParticipantSelector } from "./ParticipantSelector";
import { DateTimeSelector } from "./DateTimeSelector";
import { MarketSelector } from "./MarketSelector";
import { averagePeriod } from "../model/average-period";
import { futuresAndOptionsCombinedDatasetId } from "../model/dataset-id";
import { CotData } from "../model/cot-data";
import { DataTableCtx } from "./DataTable";
import { prepareTableData } from "../services/prepare-table-data";
import { TraderCategory } from "../model/trader-categories";
import { TableValues } from "../model/table-value";
import { exchangesWithMarkets } from "../model/exchanges-with-markets";
import { DataSetSelector } from "./DataSetSelector";

const DataTableContainer = () => {

    const DataTable = useContext(DataTableCtx)

    const [selectedExchange, setSelectedExchange] = useState('COMMODITY EXCHANGE INC.');
    const [selectedMarket, setSelectedMarket] = useState('GOLD');
    const [selectedParticipant, setSelectedParticipant] = useState('Commercial');
    const [selectedDataSetId, setSelectedDataSetid] = useState(futuresAndOptionsCombinedDatasetId);
    const [selectedDateTime, setSelectedDateTime] = useState(new Date());
    const [preparedData, setPreparedData] = useState([] as TableValues[]);

    useEffect(() => {
        // Set default values when the component mounts
        setSelectedExchange('COMMODITY EXCHANGE INC.');
        setSelectedMarket('GOLD');
    }, []);

    const handleExchangeChange = (exchange: string) => {
        setSelectedExchange(exchange);
        const [ newSelectedMarket ] =
            exchangesWithMarkets.find((ex) => ex.exchange === exchange)!.markets;
        setSelectedMarket(newSelectedMarket);

    };
    const handleMarketChange = (market: string) => setSelectedMarket(market);
    const handleParticipantChange = (participant: string) => setSelectedParticipant(participant);
    const handleDataSetChange = (dataSetId: string) => setSelectedDataSetid(dataSetId);
    const handleDateTimeChange = (date: Date) => setSelectedDateTime(date);

    const handleSubmit = async () => {
        const dsId = selectedDataSetId
        const queryParams = new URLSearchParams({
            // market_and_exchange_names: `${selectedMarket} - ${selectedExchange}`,
            // $limit: averagePeriod.toString(),
            // $offset: '0',
            // $where: `report_date_as_yyyy_mm_dd < '${selectedDateTime.toISOString().slice(0, 10)}'`
            $query: `SELECT * ` +
                `WHERE (\`contract_market_name\` = '${selectedMarket}') ` +
                `AND \`report_date_as_yyyy_mm_dd\` < '${selectedDateTime.toISOString().slice(0, 10)}' ` +
                `ORDER BY \`report_date_as_yyyy_mm_dd\` ` +
                `DESC LIMIT ${averagePeriod}`
        })
        // https://publicreporting.cftc.gov/resource/jun7-fc8e.json?
        const queryUrl = `https://publicreporting.cftc.gov/resource/${dsId}?${queryParams.toString()}`
        // Make an HTTP request
        const { data }: { data: CotData[] } = await axios.get(queryUrl)
        // console.log(`Category: ${selectedParticipant}`)
        const preparedData = data.map(e => prepareTableData(selectedParticipant as TraderCategory, e))
        setPreparedData(preparedData)
    };

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-2">
                    <DataSetSelector onSelect={handleDataSetChange} defaultDataSet={selectedDataSetId} />
                </div>
                <div className="col-md-3">
                    <ExchangeSelector onSelect={handleExchangeChange} defaultExchange={selectedExchange} />
                </div>
                <div className="col-md-2">
                    <MarketSelector selectedExchange={selectedExchange} onSelect={handleMarketChange} defaultMarket={selectedMarket} />
                </div>
                <div className="col-md-2">
                    <ParticipantSelector onSelect={handleParticipantChange} defaultParticipant={selectedParticipant} />
                </div>
                <div className="col-md-3">
                    <DateTimeSelector onChange={handleDateTimeChange} defaultValue={selectedDateTime} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-9 d-flex justify-content-start align-items-center">
                    <p>
                        {`${selectedExchange} - ${selectedMarket} - ${selectedParticipant} : ${selectedDateTime.toISOString().slice(0, 10)}`}
                    </p>
                </div>
                <div className="col-md-3 d-flex justify-content-end align-items-center">
                    <button className="btn btn-primary" onClick={handleSubmit}>Apply filters</button>
                </div>
            </div>
            <DataTable {...{ averagePeriod, values: preparedData }} />
        </div>
    );
};

export default DataTableContainer;

import React, { createContext } from 'react'

const Header = ({children}: {children: React.ReactNode}) => (
  <header className='blog-header py-3'>
    <div className='row flex-nowrap justify-content-between align-items-center'>
      {children}
    </div>
  </header>
)

export const HeaderCtx = createContext(Header)
